<template>
  <div id="app">
    <div class="index">
      <div id="main-1">
        <!-- Header -->
        <header id="header">
          <h1>{{  $t('Surpluse Trading Company Limited') }}</h1>
          <div class="about-service" v-if="langcode === 'zh'">
            營余貿易有限公司是一間海鮮食品貿易公司，多年來我們一直專注於進口世界各地優良品質的海產
          </div>
          <div class="about-service" v-if="langcode === 'en'">
            Surpluse Trading Company Limited is a seafood food trading company. For many years, we have been focusing on importing high-quality seafood from all over the world.
          </div>
          <ul class="icons">
            <li>{{ $t('Address') }}: {{ $t('Stall 32 Yuen Long Fresh Fish Market YLTL 168 30 Yuen Ching Road Yuen Long New Territories Hong Kong') }}</li>
            <li>{{ $t('Email') }}: surplusetrading@outlook.com</li>
            <li>{{ $t('Tel') }}: 852-54088222</li>
          </ul>
          <a href="#" @click="viewAboutUs">
            {{ $t('About Us')}}
          </a>
        </header>

        <!-- Footer -->
          <footer id="footer">
            <a @click="selectLang('zh')" v-if="langcode === 'en'" href="#">中文</a>
            <a @click="selectLang('en')" v-if="langcode === 'zh'" href="#">EN</a>
            <ul class="copyright">
              <li>&copy; Surpluse Trading Company Limited</li>
            </ul>
          </footer>
          <VueBotUI
          :messages="data"
          @msg-send="messageSendHandler"
          />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/01.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

    <div class="about-us">
      <div id="main-2">
        <!-- Header -->
        <header id="header">
          <h1>{{ $t('About Us') }}</h1>
          <div class="description" v-if="langcode == 'en' ">
            Products include: large carp, white eel, mandarin fish and other fresh sea fish. The styles of each type of fresh sea fish are determined according to the season and are shipped fresh every day. We have rich experience in seafood import and effective quality management mechanism. We pay attention to our customers' needs for various types of seafood and set up strict quality control mechanisms to ensure that the food is the freshest and high-quality ingredients. Due to the epidemic and the fact that traditional fish markets give customers an unhygienic feeling, citizens tend to buy ingredients online, including seafood.
          </div>
          <div class="description" v-if="langcode == 'zh' ">
            產品包括：大鯇、白鱔、桂魚等各樣新鮮海魚。各生猛海魚款式按時令而定，每天新鮮運到。我們具備豐富的海產進口經驗和有效的品質管理機制。我們注重客戶各類海產品需求，設置嚴格品質控制機制，以保證食品是最新鮮和優質的食材。因疫情關係，加上傳統魚類市場給客戶一種不太衛生的感覺，市民傾向於網上購買食材，包括海鮮食品。
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page')}}
          </a>
        </header>
        <VueBotUI
        :messages="data"
        @msg-send="messageSendHandler"
        />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/02.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { VueBotUI } from 'vue-bot-ui';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  mounted(){
    const langcode = Cookies.get('langcode');
    if(langcode){
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    }
    this.loadProducts();
    //this.loadWebsiteContent();
  },
  components: {
    VueBotUI,
  },
  data() {
    return {
      data: [
        {
          agent: 'bot',
          type: 'button',
          text: 'Hello, welcome to Surpluse Trading. How can I help you? ',
          disableInput: false,
          products: [],
        }
      ],
      replyTexts: [
        {
          'question': 'business_hour',
          'answer': "Our Business Hour is 10:00am - 17:00pm.",
        },
        {
          'question': 'place_order',
          'answer': 'If you have any questions about us, please call 852-69981318 for further follow-up after receiving the goods.',
        },
        {
          'question': 'product_source',
          'answer': "Our product come from different place, such as Hong Kong, USA or Japan.",
        },
        {
          'question': 'order_instruction',
          'answer': "If you want to order product from us, please feel free to contact us with our phone or email address.",
        },
        {
          'question': 'product_list',
          'answer': "Currently, we do not had an online product list. If had any question about it, please contact with us.",
        },
        {
          'question': 'online_quotation',
          'answer': "Please input the product name for us to reply with online quotation.",
        },
      ],
      name: '',
      descEn: '',
      descZh: '',
      contact: '',
    }
  },
  methods:{
    viewAboutUs(){
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'block';
    },
    backIndex(){
      const index = document.querySelector('.index');
      index.style.display = 'block';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
    },
    async loadConfig(){
      return new Promise(async(resolve, reject) => {
        const baseApiUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseApiUrl}/website/content`;
        const fetchResult = await fetch(apiUrl, {
          method: 'GET',
          headers: {
           'Content-type': 'application/json; charset=UTF-8'
          },
        });

        const status = await fetchResult.status;

        //If cannot connect with API server
        if(status !== 200 && status !== 401){
          const err = 'Cannot load system config. Maybe there are some network problem?';
          reject(err);
          return;
        }

        //If user do not login or no permission
        if(status === 401){
          const err = 'Sorry, You don\'t had permission to access it.';
          reject(err);
          return;
        }

        const resultJson = await fetchResult.json();

        resolve(resultJson);
      });
    },
    async loadWebsiteContent(){
      try{
        let websiteContent = await this.loadConfig();

        this.name = websiteContent['website_name'];
        this.descZh = websiteContent['website_introduce_zh'];
        this.descEn = websiteContent['website_introduce_en'];
        this.contact = websiteContent['company_contact'];

      }catch(err){
        console.log(err);
      }
    },
    selectLang(langcode){
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    },
    messageSendHandler(userAction){
      const question = userAction['value'];
      let answer = this.replyTexts.find(function(item){
        return item.question === question
      });

      const userQuestion = {
        agent: 'user',
        type: 'text',
        text: userAction['text'],
        disableInput: false,
      }
      this.data.push(userQuestion);

      const targetProduct = this.searchProduct(userAction['text']);

      if(answer){
        answer = answer['answer'];
        const reply = {
          agent: 'bot',
          type: 'text',
          text: answer,
          disableInput: false,
        }
        this.data.push(reply);
      }else if(targetProduct){
        answer = `${targetProduct.product_name_en} 的目前價格是：HK$${targetProduct.product_regular_price}`;
        const reply = {
          agent: 'bot',
          type: 'text',
          text: answer,
          disableInput: false,
        }
        this.data.push(reply);
      }else{
        const reply = {
          agent: 'bot',
          type: 'button',
          text: 'Sorry, I could not understand your question. Maybe you want to ask:',
          disableInput: false,
          options: [
            {
              text: 'Where your product come from ?',
              value: 'product_source',
              action: 'postback' // Request to API
            },
            {
              text: 'Your business hour is?',
              value: 'business_hour',
              action: 'postback' // Request to API
            },
            {
              text: 'How could I view the product list?',
              value: 'product_list',
              action: 'postback' // Request to API
            },
            {
              text: 'Ordering instructions',
              value: 'order_instruction',
              action: 'postback' // Request to API
            },
            {
              text: 'Online Quotation',
              value: 'online_quotation',
              action: 'postback' // Request to API
            },
            {
              text: 'Contact Method',
              value: 'place_order',
              action: 'postback' // Request to API
            },
          ],
        }
        this.data.push(reply);
      }
    },
    searchProduct(value){
      const productName = value;
      const targetProduct = this.products.find(( product ) => product.product_name_en === productName || product.product_name_zh === productName);
      return targetProduct;
    },
    async loadProducts(){
      const apiUrl = `${this.apiUrl}/shop/product`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading products information. Maybe there are some network problem?';
        console.log(err);
        return;
      }
      const resultJson = await fetchResult.json();
      this.products = resultJson;
    },
    ...mapActions(['changeLang']),
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
h1{
  text-align: left;
}
body.is-preload-1 #main{
  opacity: 1;
  right: 0;
}
.qkb-board{
  padding-bottom: 30px;
  width: 500px;
}
#viewer .slide .image{
  @media screen and (min-width: 1300px){
    background-size: 100%;
  }
}
.about-service{
  text-align: left;
  margin-bottom: 10px;
}
.about-us{
  display: none;
}
.description{
  text-align: left;
  margin-bottom: 20px;
}
</style>
